import classnames from 'classnames/bind'
import { MotionValue, m, useTransform, useMotionValue } from 'framer-motion'
import React, { ReactNode } from 'react'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface SequenceProps {
  className?: string
  stickyContainerClassName?: string
  children?: ReactNode
  scrollProgress?: MotionValue<number>
}

function Sequence({ className, children, scrollProgress }: SequenceProps) {
  const defaultScrollProgress = useMotionValue(0)
  const opacity = useTransform(
    scrollProgress ?? defaultScrollProgress,
    [0.2, 0.3, 0.55, 0.75],
    [1, 1, 1, 0],
  )

  return (
    <m.div
      style={{
        opacity: scrollProgress ? opacity : 'unset',
      }}
      className={cx(css.Sequence, className)}>
      {children}
    </m.div>
  )
}

export default Sequence
