import BigLink from '~/components/BigLink'
import FeaturedNews from '~/components/FeaturedNews'
import FeaturedProjects from '~/components/FeaturedProjects'
import HeroWithText from '~/components/HeroWithText'
import ImageSlider from '~/components/ImageSlider'
import LinkList from '~/components/LinkList'
import LogoGrid from '~/components/LogoGrid'
import LogoSliderWithText from '~/components/LogoSliderWithText'
import NewsGrid from '~/components/NewsGrid'
import ProjectsGrid from '~/components/ProjectsGrid'
import RichTextList from '~/components/RichTextList'
import SequencedIntro from '~/components/SequencedIntro'
import { MapSliceTypeToComponent } from '~/components/SliceManager'
import SplittedRichTextSlice from '~/components/SplittedRichTextSlice'
import TwoColumnsIntro from '~/components/TwoColumnsIntro'

/**
 * Universal map slice components imports
 */
const mapSliceToComponent: MapSliceTypeToComponent = {
  big_link: BigLink,
  featured_news: FeaturedNews,
  featured_projects: FeaturedProjects,
  hero_with_text: HeroWithText,
  image_slider: ImageSlider,
  link_list: LinkList,
  logo_slider_with_text: LogoSliderWithText,
  logo_grid: LogoGrid,
  news_grid: NewsGrid,
  projects_grid: ProjectsGrid,
  rich_text_list: RichTextList,
  sequenced_intro: SequencedIntro,
  splitted_richtext: SplittedRichTextSlice,
  two_columns_intro: TwoColumnsIntro,
}

export default mapSliceToComponent
