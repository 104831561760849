import classnames from 'classnames/bind'
import React, { Fragment } from 'react'

import ProjectCard, { ProjectCardProps } from '~/components/ProjectCard'
import { RichTextProps } from '~/components/RichText'

import { useStyle } from '~/providers/StyleProvider'

import SplittedRichText from '../SplittedRichText'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface ProjectsGridProps {
  className?: string
  title?: RichTextProps['render']
  projects?: ProjectCardProps[]
}

function ProjectsGrid({ className, projects, title }: ProjectsGridProps) {
  const gridStyle = useStyle({ grid: 'base-grid' })
  return (
    <div className={cx(css.ProjectsGrid, className)}>
      {title && (
        <SplittedRichText
          end={title}
          endRichTextClassName={css.title}
          endTextPreset="title-50-88"
        />
      )}
      <div className={cx(css.grid, gridStyle)}>
        {projects?.map((project, index) => (
          <Fragment key={`${project.name}-${index}`}>
            <ProjectCard className={css.project} {...project} />
          </Fragment>
        ))}
      </div>
    </div>
  )
}

ProjectsGrid.defaultProps = {}

export default ProjectsGrid
