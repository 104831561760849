import classnames from 'classnames/bind'
import { m, useAnimation } from 'framer-motion'
import React, { Fragment, useEffect, useMemo, useRef } from 'react'
import { useMouseHovered } from 'react-use'

import {
  useIsHover,
  useIsTouchScreen,
  useMeasure,
} from '@unlikelystudio/react-hooks'

import Link, { LinkProps } from '~/components/Link'
import MediaWithPlaceholder, {
  MediaWithPlaceholderProps,
} from '~/components/MediaWithPlaceholder'
import SVGCorner from '~/components/ProjectCard/SVGCorner'

import { usePageSettings } from '~/providers/PageSettingsProvider'
import { useStyle } from '~/providers/StyleProvider'

import Ratio from '../Ratio'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface ProjectCardProps {
  className?: string
  name: string
  layout: 'full-width' | 'half' | 'quarter'
  media: MediaWithPlaceholderProps
  link: LinkProps
  category?: string
  cover: MediaWithPlaceholderProps
}

const imageRatio = 600 / 940

const sizes = {
  'full-width': 1,
  half: 1 / 2,
  quarter: 1 / 4,
}

const transition = (i: number) => ({
  type: 'spring',
  damping: 30,
  stiffness: 400 + i * 200,
})

function ProjectCard({
  className,
  media,
  link,
  name,
  category,
  cover,
  layout = 'half',
}: ProjectCardProps) {
  const titleStyle = useStyle({ textPreset: 'title-18' })
  const textStyle = useStyle({ textPreset: 'text-13' })
  const overlayTextStyle = useStyle({ textPreset: 'text-18' })
  const [isHover, events] = useIsHover()
  const words = name?.split(' ')
  const ratioRef = useRef(null)
  const wordAnimation = useAnimation()
  const circleScaleAnimation = useAnimation()
  const circlePositionAnimation = useAnimation()
  const wordsScaleAnimation = useAnimation()
  const isTouchEvents = useIsTouchScreen()
  const { backgroundColor, color } = usePageSettings()

  const { elX, elY } = useMouseHovered(ratioRef, { whenHovered: false })

  useEffect(() => {
    const position = {
      x: elX,
      y: elY,
    }
    wordAnimation.start((i) => {
      return {
        ...position,
        transition: transition(i),
      }
    })
    circlePositionAnimation.start({
      ...position,
      opacity: 1,
      transition: { type: 'spring', damping: 50, stiffness: 600 },
    })
  }, [elX, elY])

  useEffect(() => {
    circleScaleAnimation.start({
      scale: isHover ? 1 : 0,
      y: '-50%',
      transition: isHover
        ? {
            type: 'spring',
            damping: 22,
            stiffness: 200,
            restDelta: 0.001,
          }
        : {
            type: 'spring',
            damping: 22,
            stiffness: 200,
            restDelta: 0.001,
          },
    })

    wordsScaleAnimation.start({
      opacity: isHover ? 1 : 0,
      transition: {
        type: 'tween',
        duration: 0.1,
        ease: 'linear',
      },
    })
  }, [isHover])

  const projectCover = cover || media

  return (
    <Link
      {...link}
      onMouseOver={(e) => {
        events?.onMouseEnter(e)
      }}
      onMouseOut={(e) => {
        events?.onMouseLeave(e)
      }}
      className={cx(css.ProjectCard, className, layout)}>
      <Ratio
        key="ratio"
        ref={ratioRef}
        className={css.image}
        {...{
          ...(layout === 'full-width'
            ? { preset: 'ratio-full-width-card' }
            : { ratio: imageRatio }),
        }}>
        {(className) => (
          <>
            <MediaWithPlaceholder
              sizesFromBreakpoints={[
                {
                  breakpoint: 'md',
                  ratio: sizes[layout],
                },
                { ratio: 1 },
              ]}
              layout="fill"
              objectFit="cover"
              className={cx(className, css.image)}
              withRadius
              {...projectCover}
            />
            <div className={css.overlayWrapper}>
              <div
                className={css.infos}
                style={{
                  backgroundColor,
                }}>
                <SVGCorner fill={backgroundColor} className={css.leftCorner} />
                <SVGCorner fill={backgroundColor} className={css.rightCorner} />

                {name && (
                  <span className={cx(css.title, titleStyle)}>{name}</span>
                )}
                {category && (
                  <span className={cx(css.category, textStyle)}>
                    {category}
                  </span>
                )}
              </div>
            </div>
          </>
        )}
      </Ratio>
    </Link>
  )
}

ProjectCard.defaultProps = {}

export default ProjectCard
