import classnames from 'classnames/bind'
import { m, MotionValue, useTransform } from 'framer-motion'
import React, { Ref, useRef } from 'react'

import Ratio from '~/components/Ratio'
import RichText, { RichTextProps } from '~/components/RichText'
import SplittedSection from '~/components/SplittedSection'

import { useStyle } from '~/providers/StyleProvider'

import { easeInQuint, easeOutQuint } from '~/utils/ease'

import Sequence from '../Sequence'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface SecondSequenceProps {
  className?: string
  start?: RichTextProps
  end?: RichTextProps
  animatedRef?: Ref<any>
  scrollProgress?: MotionValue<number>
}

function useAnimateText(
  sectionScrollProgress: MotionValue<number>,
  delay: number = 0,
) {
  const opacity = useTransform(
    sectionScrollProgress,
    [0.55 + delay, 0.7 + delay, 0.8 + delay, 0.85 - delay],
    [0, 1, 1, 0],
  )

  return {
    style: { opacity },
  }
}

function SecondSequence({
  className,
  start,
  end,
  animatedRef,
  scrollProgress,
}: SecondSequenceProps) {
  const richTextStyle = useStyle({ textPreset: 'rich-text-variant-1' })
  const sectionRef = useRef(null)

  const sectionScrollProgress = useTransform(
    scrollProgress,
    [1 / 3, 2.2 / 3],
    [0, 1],
    {
      clamp: true,
    },
  )

  const startStyle = useAnimateText(sectionScrollProgress, 0)
  const endStyle = useAnimateText(sectionScrollProgress, 0.02)

  return (
    <Sequence className={cx(className)}>
      <SplittedSection
        ref={sectionRef}
        startClassName={css.start}
        endClassName={css.end}
        start={
          <Ratio ref={animatedRef} ratio={1} className={css.square}>
            {(className) => <div className={cx(className, css.circle)} />}
          </Ratio>
        }
        end={
          <>
            <m.div {...startStyle} className={css.animatedSequence}>
              <RichText className={cx(richTextStyle)} render={start} />
            </m.div>
            <m.div {...endStyle} className={css.animatedSequence}>
              <RichText
                className={cx(richTextStyle, css.endBottom)}
                render={end}
              />
            </m.div>
            <div className={css.deviceSequence}>
              <RichText className={cx(richTextStyle)} render={start} />
              <RichText
                className={cx(richTextStyle, css.endBottom)}
                render={end}
              />
            </div>
          </>
        }
        className={cx(css.SecondSequence)}
      />
    </Sequence>
  )
}

SecondSequence.defaultProps = {}

export default SecondSequence
