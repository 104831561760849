import classnames from 'classnames/bind'
import {
  useMotionValue,
  useTransform,
  m,
  useMotionTemplate,
} from 'framer-motion'
import React, { useRef } from 'react'

import RichText, { RichTextProps } from '~/components/RichText'
import SplittedSection from '~/components/SplittedSection'

import { useStyle } from '~/providers/StyleProvider'

import useElevator from '~/hooks/useElevator'

import { ReactComponent as StarIcon } from './star.svg'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface TwoColumnsIntroProps {
  className?: string
  start?: RichTextProps['render'][]
  end?: RichTextProps['render'][]
}

function TwoColumnsIntro({ className, start, end }: TwoColumnsIntroProps) {
  const titleTextStyle = useStyle({ textPreset: 'title-30-88' })
  const richTextStyle = useStyle({ textPreset: 'rich-text-variant-1' })

  const progress = useMotionValue(0)
  const sectionRef = useRef(null)
  const rotate = useTransform(progress, [0, 1], [-800, 800])
  const transformStyle = useMotionTemplate`rotate3d(1, 1, 0.5, ${rotate}deg)`

  useElevator({
    ref: sectionRef,
    friction: 0.06,
    onProgress: (progressY) => {
      progress.set(progressY)
    },
  })

  return (
    <SplittedSection
      ref={sectionRef}
      startClassName={cx(css.start)}
      start={
        <>
          {start?.[0] && (
            <RichText
              render={start?.[0]}
              className={cx(css.startRichText, richTextStyle)}
            />
          )}
          <div className={css.starIconContainer}>
            <m.div
              className={css.starIconGroup}
              style={{
                transform: transformStyle,
              }}>
              <StarIcon className={css.starIcon} />
              <StarIcon className={css.starIcon} />
              <StarIcon className={css.starIcon} />
            </m.div>
          </div>
        </>
      }
      endClassName={css.end}
      end={
        <>
          {end?.[0] && (
            <RichText
              render={end?.[0]}
              className={cx(richTextStyle, css.endRichText)}
            />
          )}
          {end?.[1] && (
            <RichText
              render={end?.[1]}
              className={cx(richTextStyle, css.endRichText)}
            />
          )}
        </>
      }
      className={cx(className, css.TwoColumnsIntro)}
      withVerticalSeparator
      withBottomSeparator
    />
  )
}

export default TwoColumnsIntro
