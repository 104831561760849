import classnames from 'classnames/bind'
import { m, useAnimation } from 'framer-motion'
import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

import { useWindowSize } from '@unlikelystudio/react-hooks'

import LogoGridItem, {
  LogoGridItemProps,
} from '~/components/LogoGrid/LogoGridItem'

import { useStyle } from '~/providers/StyleProvider'

import RichText, { RichTextProps } from '../RichText'
import Separator from '../Separator'
import css from './styles.module.scss'

const cx = classnames.bind(css)
export interface LogoGridProps {
  className?: string
  title?: RichTextProps['render']
  logos?: LogoGridItemProps[]
}

function LogoGrid({ className, title, logos }: LogoGridProps) {
  const titleStyle = useStyle({ textPreset: 'title-50-88' })
  const gridStyle = useStyle({ grid: 'base-grid' })
  const { height: windowHeight } = useWindowSize()

  const [inViewRef, inView] = useInView({
    initialInView: false,
    rootMargin: `-200px`,
  })

  return (
    <div className={cx(css.LogoGrid, className)}>
      <Separator className={css.separator} />
      <div className={cx(gridStyle)}>
        <RichText render={title} className={cx(titleStyle, css.title)} />
      </div>
      <ul className={cx(gridStyle, css.ul)} ref={inViewRef}>
        {logos?.map?.((logo, index) => (
          <LogoGridItem
            index={index}
            inView={inView}
            className={css.logo}
            key={index}
            {...logo}
          />
        ))}
      </ul>
    </div>
  )
}

export default LogoGrid
