import classnames from 'classnames/bind'
import React from 'react'

import { useStyle } from '~/providers/StyleProvider'

import InlineCta, { InlineCtaProps } from '../InlineCta'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface SeeMoreProps {
  className?: string
  link?: InlineCtaProps
  title?: string
}

function SeeMore({ className, title, link }: SeeMoreProps) {
  const titlePreset = useStyle({ textPreset: 'title-18' })
  const gridStyle = useStyle({ grid: 'base-grid' })

  return (
    <div className={cx(css.SeeMore, className, gridStyle)}>
      <div className={css.container}>
        {title && <div className={titlePreset}>{title}</div>}
        {link && (
          <InlineCta
            className={css.cta}
            withArrow
            textPreset="cta-18"
            {...link}
          />
        )}
      </div>
    </div>
  )
}

SeeMore.defaultProps = {}

export default SeeMore
