import classnames from 'classnames/bind'
import { m, MotionValue, useTransform } from 'framer-motion'
import React, { Ref } from 'react'

import MediaWithPlaceholder, {
  MediaWithPlaceholderProps,
} from '~/components/MediaWithPlaceholder'
import Ratio from '~/components/Ratio'
import RichText, { RichTextProps } from '~/components/RichText'
import SplittedSection from '~/components/SplittedSection'

import { useStyle } from '~/providers/StyleProvider'

import { easeInQuint, easeOutExpo } from '~/utils/ease'

import Sequence from '../Sequence'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface FirstSequenceProps {
  className?: string
  animatedRef?: Ref<any>
  start?: RichTextProps
  endFirstColumn?: RichTextProps
  endSecondColumn?: RichTextProps
  media?: MediaWithPlaceholderProps
  scrollProgress?: MotionValue<number>
}

function useAnimateText(
  sectionScrollProgress: MotionValue<number>,
  delay: number = 0,
) {
  const y = useTransform(
    sectionScrollProgress,
    [0.2 + delay, 0.4 + delay, 0.8, 1 - delay],
    [80, 0, 0, 0],
    {
      ease: [easeOutExpo, easeOutExpo, easeInQuint],
    },
  )

  return {
    style: { y },
  }
}

function FirstSequence({
  className,
  start,
  endFirstColumn,
  endSecondColumn,
  media,
  animatedRef,
  scrollProgress,
}: FirstSequenceProps) {
  const richTextStyle = useStyle({ textPreset: 'rich-text-variant-1' })

  const sectionScrollProgress = useTransform(scrollProgress, [0, 0.6], [0, 1], {
    clamp: true,
  })

  const titleStyle = useAnimateText(sectionScrollProgress, 0)
  const startStyle = useAnimateText(sectionScrollProgress, 0.04)
  const endStyle = useAnimateText(sectionScrollProgress, 0.07)

  return (
    <Sequence scrollProgress={sectionScrollProgress} className={cx(className)}>
      <SplittedSection
        withVerticalSeparator
        startClassName={css.start}
        endClassName={css.end}
        start={
          <>
            {start && (
              <m.div className={css.animatedSequence} {...titleStyle}>
                <RichText className={richTextStyle} render={start} />
              </m.div>
            )}
            <div className={cx(css.startBottom, css.animatedSequence)}>
              {endFirstColumn && (
                <m.div {...startStyle} className={css.bottomRichText}>
                  <RichText
                    className={cx(richTextStyle)}
                    render={endFirstColumn}
                  />
                </m.div>
              )}
              {endFirstColumn && (
                <m.div
                  {...endStyle}
                  transition={{ delay: 0.2 }}
                  className={cx(css.bottomRichText, css.endSecondColumn)}>
                  <RichText
                    className={cx(richTextStyle)}
                    render={endSecondColumn}
                  />
                </m.div>
              )}
            </div>
            <div className={css.deviceSequence}>
              {start && <RichText className={richTextStyle} render={start} />}
              <div className={css.startBottom}>
                {endFirstColumn && (
                  <div className={css.bottomRichText}>
                    <RichText
                      className={cx(richTextStyle)}
                      render={endFirstColumn}
                    />
                  </div>
                )}
                {endFirstColumn && (
                  <div className={cx(css.bottomRichText, css.endSecondColumn)}>
                    <RichText
                      className={cx(richTextStyle)}
                      render={endSecondColumn}
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        }
        end={
          <>
            <Ratio ref={animatedRef} className={css.ratio} ratio={1}>
              {(className) => (
                <MediaWithPlaceholder
                  {...media}
                  layout="fill"
                  objectFit="cover"
                  className={cx(className, css.image)}
                />
              )}
            </Ratio>
          </>
        }
        className={cx(css.FirstSequence)}
      />
    </Sequence>
  )
}

FirstSequence.defaultProps = {}

export default FirstSequence
