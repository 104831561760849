import classnames from 'classnames/bind'
import React, { useRef, useState } from 'react'

import Slider from '@unlikelystudio/react-slider'

import Image, { ImageProps } from '~/components/Image'
import Link, { LinkProps } from '~/components/Link'
import RichText, { RichTextProps } from '~/components/RichText'
import SplittedSection from '~/components/SplittedSection'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

interface Logo {
  link?: LinkProps
  image?: ImageProps
  color?: string
}

export interface LogoSliderWithTextProps {
  className?: string
  start?: RichTextProps['render'][]
  logos: Logo[]
}

function LogoSliderWithText({
  className,
  start,
  logos,
}: LogoSliderWithTextProps) {
  const sliderContainerRef = useRef(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const titleStyle = useStyle({ textPreset: 'title-30-88' })
  const richTextStyle = useStyle({ textPreset: 'rich-text-variant-1' })
  return (
    <SplittedSection
      start={
        <>
          {start?.[0] && (
            <RichText className={cx(titleStyle, css.title)} render={start[0]} />
          )}
          {start?.[1] && (
            <RichText
              className={cx(richTextStyle, css.text)}
              render={start[1]}
            />
          )}
        </>
      }
      endClassName={css.end}
      end={
        <div className={css.endWrapper}>
          <div
            ref={sliderContainerRef}
            className={css.sliderContainer}
            style={{ backgroundColor: logos?.[currentIndex]?.color }}>
            <Slider
              customSliderRef={sliderContainerRef}
              containerClassName={css.slides}
              onSlideIndexChange={(index) => setCurrentIndex(index)}
              className={css.logoSlider}
              infinite
              snap={false}
              autoPlay={{ speed: -0.8 }}>
              {logos?.map((logo, index) => {
                return (
                  <Link className={css.link} {...logo?.link} key={index}>
                    <img
                      className={cx(css.logo, className)}
                      layout="fill"
                      {...logo?.image}
                    />
                  </Link>
                )
              })}
            </Slider>
          </div>
        </div>
      }
      withTopSeparator
      withBottomSeparator
      className={cx(css.LogoSliderWithText, className)}></SplittedSection>
  )
}

LogoSliderWithText.defaultProps = {}

export default LogoSliderWithText
