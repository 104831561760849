import classnames from 'classnames/bind'
import React, { ReactNode } from 'react'

import SeeMore, { SeeMoreProps } from '../SeeMore'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface FeaturedTypeProps {
  className?: string
  children?: ReactNode[] | ReactNode
  seeMore?: SeeMoreProps
}

function FeaturedType({ className, children, seeMore }: FeaturedTypeProps) {
  return (
    <div className={cx(css.FeaturedType, className)}>
      {seeMore && <SeeMore {...seeMore} />}
      {children}
    </div>
  )
}

FeaturedType.defaultProps = {}

export default FeaturedType
