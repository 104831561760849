import classnames from 'classnames/bind'
import React from 'react'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface SVGCornerProps {
  className?: string
  fill?: string
}

function SVGCorner({ className, fill }: SVGCornerProps) {
  return (
    <svg
      className={cx(css.SVGCorner, className)}
      width="4"
      height="4"
      viewBox="0 0 4 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        d="M0 4C2.20914 4 4 2.20914 4 0V4H0Z"
        fill={fill}
      />
    </svg>
  )
}

SVGCorner.defaultProps = {}

export default SVGCorner
