import classnames from 'classnames/bind'
import { m, useAnimation } from 'framer-motion'
import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

import { useIsHover, useWindowSize } from '@unlikelystudio/react-hooks'

import Image, { ImageProps } from '~/components/Image'
import Link, { LinkProps } from '~/components/Link'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface LogoGridItemProps {
  className?: string
  index?: number
  image?: ImageProps
  link?: LinkProps
  inView?: boolean
}

const transition = (i: number) => ({
  type: 'tween',
  delay: ((i + 1) * 0.15) / 3,
  duration: 0.3,
})

function LogoGridItem({
  className,
  image,
  link,
  index,
  inView,
}: LogoGridItemProps) {
  const isLink = link?.href
  const wordAnimation = useAnimation()
  const [isHover, hoverEvents] = useIsHover()

  useEffect(() => {
    wordAnimation.start((i) => {
      return {
        y: inView ? 0 : 50,
        opacity: inView ? 1 : 0,
        transition: transition(i),
      }
    })
  }, [inView])

  return (
    <m.li
      custom={index}
      animate={wordAnimation}
      className={cx(className, css.LogoGridItem, { isHover })}
      initial={{
        y: 50,
        opacity: 0,
      }}>
      {isLink ? (
        <Link {...link} {...hoverEvents}>
          <img {...image} />
        </Link>
      ) : (
        <img {...image} />
      )}
    </m.li>
  )
}

LogoGridItem.defaultProps = {}

export default LogoGridItem
