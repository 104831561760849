import classnames from 'classnames/bind'
import React, { useRef } from 'react'

import Link from '~/components/Link'
import MediaWithPlaceholder, {
  MediaWithPlaceholderProps,
} from '~/components/MediaWithPlaceholder'
import SVGCorner from '~/components/ProjectCard/SVGCorner'
import RichText, { RichTextProps } from '~/components/RichText'

import { usePageSettings } from '~/providers/PageSettingsProvider'
import { useStyle } from '~/providers/StyleProvider'

import useParallax from '~/hooks/useParallax'

import SplittedRichText from '../SplittedRichText'
//import { ReactComponent as ArrowIcon } from './arrow.svg'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface HeroWithTextProps {
  className?: string
  text?: RichTextProps
  introText?: RichTextProps
  smallScreenMedia?: MediaWithPlaceholderProps
  layout?: 'single-column' | 'double-column'
  media?: MediaWithPlaceholderProps
  title?: Text
  description?: Text
  link?: any
}

function HeroWithText({
  className,
  text,
  introText,
  smallScreenMedia,
  layout = 'double-column',
  media,
  title,
  description,
  link,
}: HeroWithTextProps) {
  const titleStyle = useStyle({ textPreset: 'title-18' })
  const textStyle = useStyle({ textPreset: 'text-13' })
  const richTextStyle = useStyle({ textPreset: 'rich-text-variant-1' })
  const componentRef = useRef<HTMLDivElement>()
  const imageRef = useRef(null)
  const { y } = useParallax({ ref: imageRef })
  const { backgroundColor } = usePageSettings()

  const renderCardWithLink = () => {
    const Card = (
      <div className={cx(css.mediaWrapper)}>
        <MediaWithPlaceholder
          ref={imageRef}
          // motionStyle={{ y, z: 6 }}
          className={cx(css.hideOnSmallScreen, css.image)}
          {...media}
        />
        {(smallScreenMedia || media?.imageProps) && (
          <MediaWithPlaceholder
            className={cx(css.hideOnLargeScreen, css.image)}
            {...(smallScreenMedia ?? media?.imageProps)}
          />
        )}

        {title && description && (
          <div className={css.overlayWrapper}>
            <div
              className={css.infos}
              style={{
                backgroundColor,
              }}>
              <SVGCorner fill={backgroundColor} className={css.leftCorner} />
              <SVGCorner fill={backgroundColor} className={css.rightCorner} />

              {title && (
                <span className={cx(css.title, titleStyle)}>
                  {/* <ArrowIcon className={css.arrow} /> */}
                  {title}
                </span>
              )}

              {description && (
                <span className={cx(css.category, textStyle)}>
                  {description}
                </span>
              )}
            </div>
          </div>
        )}
      </div>
    )

    return link ? (
      <Link className={css.link} href={link?.full_slug}>
        {Card}
      </Link>
    ) : (
      Card
    )
  }

  return (
    <SplittedRichText
      ref={componentRef}
      startTextPreset="rich-text-variant-1"
      endTextPreset="rich-text-variant-1"
      {...(layout === 'double-column'
        ? {
            start: introText,
            end: text,
          }
        : {})}
      startRichTextClassName={css.richTextClassName}
      endRichTextClassName={css.richTextClassName}
      className={cx(className, css.HeroWithText, {
        noMarginTop: introText === null,
      })}
      splittedSectionProps={{
        startClassName: css.start,
        topChildren:
          introText && layout === 'single-column' ? (
            <RichText
              className={cx(richTextStyle, css.singleColumnRichText)}
              render={introText}
            />
          ) : null,
        bottomChildren: renderCardWithLink(),
      }}
    />
  )
}

export default HeroWithText
