import classnames from 'classnames/bind'
import React from 'react'

import SplittedRichText, {
  SplittedRichtTextProps,
} from '~/components/SplittedRichText'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface SplittedRichTextSliceProps extends SplittedRichtTextProps {
  className?: string
  withTopSeparator?: boolean
  withBottomSeparator?: boolean
  columnSize?: string
}

function SplittedRichTextSlice({
  className,
  start,
  end,
  withTopSeparator = false,
  withBottomSeparator = true,
  columnSize,
}: SplittedRichTextSliceProps) {
  return (
    <SplittedRichText
      startRichTextClassName={
        columnSize === 'largeLeft' ? css.largeColumnSize : css.defaultColumnSize
      }
      endRichTextClassName={
        columnSize === 'largeRight'
          ? css.largeColumnSize
          : css.defaultColumnSize
      }
      start={start}
      startTextPreset="rich-text-variant-2"
      end={end}
      splittedSectionProps={{
        withTopSeparator: withTopSeparator,
        withBottomSeparator: withBottomSeparator ? false : true,
        startClassName: css.start,
        endClassName: css.end,
      }}
      className={cx(css.SplittedRichTextSlice, className)}
    />
  )
}

SplittedRichTextSlice.defaultProps = {}

export default SplittedRichTextSlice
