import classnames from 'classnames/bind'
import React from 'react'

import FeaturedType from '~/components/FeaturedType'
import { ProjectCardProps } from '~/components/ProjectCard'
import ProjectsGrid from '~/components/ProjectsGrid'
import { SeeMoreProps } from '~/components/SeeMore'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface FeaturedProjectsProps {
  className?: string
  projects?: ProjectCardProps[]
  seeMore?: SeeMoreProps
}

function FeaturedProjects({
  className,
  projects,
  seeMore,
}: FeaturedProjectsProps) {
  return (
    <FeaturedType
      className={cx(css.FeaturedProjects, className)}
      seeMore={seeMore}>
      <ProjectsGrid projects={projects} />
    </FeaturedType>
  )
}

FeaturedProjects.defaultProps = {}

export default FeaturedProjects
