import { useIsPresent } from 'framer-motion'
import { useContext } from 'react'

// import { useEffect } from 'react'
import { useElevator, useWindowSize } from '@unlikelystudio/react-hooks'

import { PageYContext } from '~/components/Page'

export default function useCustomElevator(
  props: Parameters<typeof useElevator>[0],
) {
  const isPresent = useIsPresent()
  const pageY = useContext(PageYContext)

  return useElevator({
    ...props,
    pageY,
    active: isPresent,
  })
}
