import classnames from 'classnames/bind'
import React, { forwardRef } from 'react'

import RichText, { RichTextProps } from '~/components/RichText'
import SplittedSection, {
  SplittedSectionProps,
} from '~/components/SplittedSection'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface SplittedRichtTextProps {
  className?: string
  startRichTextClassName?: string
  endRichTextClassName?: string
  start?: RichTextProps['render'] | RichTextProps['render'][]
  startTextPreset?: string
  endTextPreset?: string
  end?: RichTextProps['render'] | RichTextProps['render'][]
  splittedSectionProps?: SplittedSectionProps
}

const SplittedRichText = forwardRef<HTMLDivElement, SplittedRichtTextProps>(
  (
    {
      className,
      start,
      end,
      startTextPreset,
      endTextPreset,
      splittedSectionProps,
      startRichTextClassName,
      endRichTextClassName,
    }: SplittedRichtTextProps,
    ref,
  ) => {
    const startTextStyle = useStyle({
      textPreset: startTextPreset ?? 'rich-text-variant-1',
    })
    const endTextStyle = useStyle({
      textPreset: endTextPreset ?? 'rich-text-variant-1',
    })

    return (
      <SplittedSection
        ref={ref}
        className={cx(className, css.SplittedSection)}
        start={
          start &&
          (start?.length > 0 ? (
            start?.map?.((richText, index) => (
              <RichText
                className={cx(startTextStyle, startRichTextClassName)}
                key={index}
                render={richText}
              />
            ))
          ) : (
            <RichText
              className={cx(startTextStyle, startRichTextClassName)}
              render={start}
            />
          ))
        }
        end={
          end &&
          (end?.length > 0 ? (
            end?.map?.((richText, index) => (
              <RichText
                className={cx(endTextStyle, endRichTextClassName, css.richText)}
                key={index}
                render={richText}
              />
            ))
          ) : (
            <RichText
              className={cx(endTextStyle, endRichTextClassName, css.richText)}
              render={end}
            />
          ))
        }
        {...splittedSectionProps}
      />
    )
  },
)

export default SplittedRichText
