import classnames from 'classnames/bind'
import React from 'react'

import Link, { LinkProps } from '~/components/Link'

import { usePageTransitionProvider } from '~/providers/PageTransitionProvider'
import { useStyle } from '~/providers/StyleProvider'

import MediaWithPlaceholder, {
  MediaWithPlaceholderProps,
} from '../MediaWithPlaceholder'
import RichText from '../RichText'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface NewsCardProps {
  className?: string
  media: MediaWithPlaceholderProps
  link: LinkProps
  name: string
  publishedAt: Date
  firstPublishedAt: Date
  isExternal: Boolean
  description: any
  category?: string
}

function NewsCard({
  className,
  media,
  link,
  name,
  publishedAt,
  firstPublishedAt,
  description,
  category,
}: NewsCardProps) {
  const publishedAtTextStyle = useStyle({ textPreset: 'text-13' })
  const nameTextStyle = useStyle({ textPreset: 'rich-text-variant-1' })
  const descriptionTextStyle = useStyle({ textPreset: 'text-15' })
  const { isTransitioning } = usePageTransitionProvider()

  console.log(description)

  const hasDateOrCategory = publishedAt && category

  return (
    <Link {...link} className={cx(css.NewsCard, className)}>
      <MediaWithPlaceholder
        className={cx(css.image, className)}
        withRadius
        layout="responsive"
        shouldPlayVideo={!isTransitioning}
        {...media}
      />
      {firstPublishedAt && (
        <p className={cx(css.pusblishedAt, publishedAtTextStyle)}>
          {category}
          {hasDateOrCategory && <span className={css.separator}>|</span>}
          {firstPublishedAt}
        </p>
      )}
      {name && <p className={cx(css.name, nameTextStyle)}>{name}</p>}

      {description && (
        <RichText
          className={cx(css.description, descriptionTextStyle)}
          render={description[0]?.content}
        />
      )}
    </Link>
  )
}

NewsCard.defaultProps = {}

export default NewsCard
