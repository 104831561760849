import classnames from 'classnames/bind'
import { useMotionValue } from 'framer-motion'
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from 'react'
import { useInView } from 'react-intersection-observer'

import { useWindowSize } from '@unlikelystudio/react-hooks'

import useElevator from '~/hooks/useElevator'

import AnimatedCanvas from './AnimatedCanvas'
import FirstSequence, { FirstSequenceProps } from './FirstSequence'
import SecondSequence, { SecondSequenceProps } from './SecondSequence'
import ThirdSequence, { ThirdSequenceProps } from './ThirdSequence'
import css from './styles.module.scss'

const ctx = createContext<ReturnType<typeof useState>>([
  0,
  (_value: number) => {},
])
const cx = classnames.bind(css)

export interface SequencedIntroProps {
  className?: string
  firstSequence?: FirstSequenceProps
  secondSequence?: SecondSequenceProps
  thirdSequence?: ThirdSequenceProps
}

export function useSequenceContext() {
  return useContext(ctx)
}

export function useSequence(index: number) {
  const [sequenceIndex, setSequence] = useSequenceContext()
  const [setRef, inView] = useInView()
  const currentSequence = sequenceIndex === index

  useEffect(() => {
    if (inView) {
      setSequence(index)
    }
  }, [inView, index, setSequence])

  return [setRef, currentSequence] as const
}

function SequencedIntro({
  className,
  firstSequence,
  secondSequence,
  thirdSequence,
}: SequencedIntroProps) {
  const firstSequenceAnimatedRef = useRef<HTMLElement>()
  const secondSequenceAnimatedRef = useRef<HTMLElement>()
  const thirdSequenceAnimatedRef = useRef<HTMLElement>()

  // This value can be tweaked a little if needed in order to start the animation a bit earlier
  const { height } = useWindowSize()

  const sectionRef = useRef(null)
  const scrollProgress = useMotionValue(0)

  useElevator({
    ref: sectionRef,
    offset: height,
    friction: 0.08,
    onProgress: (progress) => {
      scrollProgress.set(progress)
    },
  })

  return (
    <div ref={sectionRef} className={cx(css.SequencedIntro, className)}>
      <AnimatedCanvas
        {...{
          firstSequenceAnimatedRef,
          secondSequenceAnimatedRef,
          thirdSequenceAnimatedRef,
        }}
        firstSequenceImage={firstSequence?.media?.imageProps}
        thirdSequenceImage={firstSequence?.media?.imageProps}
        className={css.animatedCanvas}
      />
      <div className={css.stickyContainer}>
        {firstSequence && (
          <FirstSequence
            animatedRef={firstSequenceAnimatedRef}
            scrollProgress={scrollProgress}
            className={css.sequence}
            {...firstSequence}
          />
        )}
        {secondSequence && (
          <SecondSequence
            animatedRef={secondSequenceAnimatedRef}
            scrollProgress={scrollProgress}
            className={css.sequence}
            {...secondSequence}
          />
        )}
        {thirdSequence && (
          <ThirdSequence
            animatedRef={thirdSequenceAnimatedRef}
            className={css.sequence}
            {...thirdSequence}
          />
        )}
      </div>
    </div>
  )
}

SequencedIntro.defaultProps = {}

export default SequencedIntro
