import React, { ComponentType } from 'react'

export type MapSliceTypeToComponent = Record<string, ComponentType<any>>
export type DefaultSliceData = Record<string, any>

export interface Slice<D = DefaultSliceData> {
  type: string
  data: D
}

export interface SliceManagerProps {
  slicesClassName?: string
  mapSliceTypeToComponent: MapSliceTypeToComponent
  slicesData: Slice[]
}
/**
 *
 * @param {string} slicesClassName - Custom classname
 * @param {MapSliceTypeToComponent} mapSliceTypeToComponent - Map components prismic key = react component
 * @param {Slice[]} slicesData - Data from prismic slices
 * @returns
 */
function SliceManager({
  slicesClassName,
  mapSliceTypeToComponent,
  slicesData,
}: SliceManagerProps) {
  return (
    <>
      {slicesData?.map((sliceData, index) => {
        const SliceComponent = mapSliceTypeToComponent?.[sliceData?.type]

        if (!SliceComponent) return null
        return (
          <SliceComponent
            key={index}
            className={slicesClassName}
            {...sliceData?.data}
          />
        )
      })}
    </>
  )
}

SliceManager.defaultProps = {}

export default SliceManager
