import classnames from 'classnames/bind'
import React from 'react'

import NewsCard, { NewsCardProps } from '~/components/NewsCard'
import { RichTextProps } from '~/components/RichText'

import { useStyle } from '~/providers/StyleProvider'

import SplittedRichText from '../SplittedRichText'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface NewsGridProps {
  className?: string
  title?: RichTextProps['render']
  news?: NewsCardProps[]
}

function NewsGrid({ className, title, news }: NewsGridProps) {
  const gridStyle = useStyle({ grid: 'base-grid' })
  return (
    <div className={cx(css.NewsGrid, className)}>
      {title && (
        <SplittedRichText
          end={title}
          endRichTextClassName={css.title}
          endTextPreset="title-50-88"
        />
      )}
      <div className={gridStyle}>
        {news?.map((news, index) => (
          <NewsCard
            key={`${news.name}-${index}`}
            className={css.newsCard}
            {...news}
          />
        ))}
      </div>
    </div>
  )
}

NewsGrid.defaultProps = {}

export default NewsGrid
