import { useMotionValue, useTransform } from 'framer-motion'
import { RefObject } from 'react'

import useElevator from '~/hooks/useElevator'

export interface ParallaxParams {
  friction?: number
  displacement?: string
  unzoom?: number
  ref: RefObject<HTMLElement>
}

export default function useParallax({
  ref,
  friction = 0.9,
  displacement = '9%',
}: ParallaxParams) {
  const scrollYProgress = useMotionValue(0)
  useElevator({
    ref,
    friction,
    onProgress: (p) => {
      scrollYProgress.set(p)
    },
  })
  const y = useTransform(
    scrollYProgress,
    [0, 1],
    [`-${displacement}`, displacement],
  )

  return { y } as const
}
