import classnames from 'classnames/bind'
import React, { useRef } from 'react'

import Slider from '@unlikelystudio/react-slider'

import MediaWithPlaceholder, {
  MediaWithPlaceholderProps,
} from '~/components/MediaWithPlaceholder'
import Ratio from '~/components/Ratio'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface ImageSliderProps {
  className?: string
  media?: MediaWithPlaceholderProps[]
  imageRatio?: number
}

const sizesFromBreakpoints = [
  {
    breakpoint: 'md',
    ratio: 43 / 60,
  },
  { ratio: 23 / 30 },
]

function ImageSlider({ className, media, imageRatio }: ImageSliderProps) {
  const containerRef = useRef(null)
  const gridStyle = useStyle({ grid: 'base-grid' })

  return (
    <div ref={containerRef} className={cx(css.ImageSlider, className)}>
      <div className={gridStyle}>
        <Slider
          maxSlideIndexChange={1}
          customSliderRef={containerRef}
          className={css.slider}
          snap>
          {media?.map?.((media) => {
            return (
              <Ratio
                className={cx(css.ratio, {
                  portrait: imageRatio < 1,
                })}
                key={`${media?.id}-index`}
                ratio={imageRatio || 1.18}>
                {(className) => (
                  <MediaWithPlaceholder
                    {...media}
                    layout="fill"
                    sizesFromBreakpoints={sizesFromBreakpoints}
                    objectFit="cover"
                    className={cx(css.image, className)}
                  />
                )}
              </Ratio>
            )
          })}
        </Slider>
      </div>
    </div>
  )
}

export default ImageSlider
