import classnames from 'classnames/bind'
import React from 'react'

import Link, { LinkProps } from '~/components/Link'
import MediaWithPlaceholder, {
  MediaWithPlaceholderProps,
} from '~/components/MediaWithPlaceholder'
import RichText, { RichTextProps } from '~/components/RichText'

import { useStyle } from '~/providers/StyleProvider'

import FeaturedType from '../FeaturedType'
import InlineCta from '../InlineCta'
import { SeeMoreProps } from '../SeeMore'
import SplittedSection from '../SplittedSection'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface FeaturedNewsProps {
  className?: string
  description?: RichTextProps['render']
  title?: string
  publicationDate?: string
  backgroundColor?: string
  link?: LinkProps
  seeMore?: SeeMoreProps
  media?: MediaWithPlaceholderProps
}

function FeaturedNews({
  className,
  publicationDate,
  media,
  title,
  backgroundColor,
  description,
  link,
  seeMore,
}: FeaturedNewsProps) {
  const dateTextStyle = useStyle({ textPreset: 'text-13' })
  const titleTextStyle = useStyle({ textPreset: 'title-30' })
  const descriptionStyle = useStyle({ textPreset: 'rich-text-variant-3' })

  return (
    <FeaturedType seeMore={seeMore} className={cx(css.FeaturedNews, className)}>
      <SplittedSection
        startClassName={css.start}
        start={
          <Link {...link} style={{ backgroundColor }} className={css.newsCard}>
            <span className={css.startCard}>
              {publicationDate && (
                <div className={cx(dateTextStyle, css.publicationDate)}>
                  {publicationDate}
                </div>
              )}
              {title && (
                <div className={cx(css.title, titleTextStyle)}>{title}</div>
              )}
            </span>
            <div className={css.descriptionContainer}>
              {description && (
                <RichText
                  className={cx(css.description, descriptionStyle)}
                  render={description}
                />
              )}
              <InlineCta className={css.cta}>Learn more</InlineCta>
            </div>
          </Link>
        }
        end={
          <MediaWithPlaceholder
            sizesFromBreakpoints={[
              {
                breakpoint: 'md',
                ratio: 0.5,
              },
              {
                ratio: 1,
              },
            ]}
            layout="fill"
            objectFit="cover"
            className={css.image}
            {...media}
            withRadius
          />
        }
      />
    </FeaturedType>
  )
}

FeaturedNews.defaultProps = {}

export default FeaturedNews
