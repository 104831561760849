import classnames from 'classnames/bind'
import { MotionValue, useMotionValue, useTransform } from 'framer-motion'
import { TransformOptions } from 'framer-motion/types/utils/transform'
import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { useMeasureObserver } from '@unlikelystudio/react-hooks'

import { ImageProps } from '~/components/Image'

import useElevator from '~/hooks/useElevator'

import { easeInOutCubic, easeInOutQuart } from '~/utils/ease'
import { cover } from '~/utils/maths/fit'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface AnimatedCanvasProps {
  firstSequenceImage: ImageProps
  thirdSequenceImage: ImageProps
  firstSequenceAnimatedRef: RefObject<HTMLElement>
  secondSequenceAnimatedRef: RefObject<HTMLElement>
  thirdSequenceAnimatedRef: RefObject<HTMLElement>
  className?: string
}

function drawCircle(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  radius: number,
) {
  ctx.beginPath()
  ctx.arc(x + radius, y + radius, radius, 0, 2 * Math.PI)
}

function drawImage(
  ctx: CanvasRenderingContext2D,
  image: HTMLImageElement,
  x: number,
  y: number,
  width: number,
  height: number,
) {
  ctx.drawImage(image, x, y, width, height)
}

function drawRoundSquare(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  size: number,
  radius: number,
) {
  ctx.beginPath()
  ctx.moveTo(x + radius, y)
  ctx.arcTo(x + size, y, x + size, y + size, radius)
  ctx.arcTo(x + size, y + size, x, y + size, radius)
  ctx.arcTo(x, y + size, x, y, radius)
  ctx.arcTo(x, y, x + size, y, radius)
  ctx.closePath()
}

function useScrollTransform(
  value: number[],
  progress: MotionValue<number>,
  ease?: TransformOptions<any>['ease'],
) {
  return useTransform(progress, [0, 0.2, 0.5, 0.75, 1], value, {
    ease: ease ?? easeInOutQuart,
  })
}

function useCanvasImage(src: string) {
  const imageRef = useRef<HTMLImageElement>()
  const [isLoaded, setIsLoaded] = useState(false)
  useEffect(() => {
    imageRef.current = new Image()
    imageRef.current.src = src
  }, [src])

  useEffect(() => {
    imageRef.current.onload = () => setIsLoaded(true)

    return () => {
      setIsLoaded(false)
      imageRef.current.onload = null
    }
  }, [src])

  return [imageRef, isLoaded] as const
}

function AnimatedCanvas({
  className,
  firstSequenceImage,
  thirdSequenceImage,
  firstSequenceAnimatedRef,
  secondSequenceAnimatedRef,
  thirdSequenceAnimatedRef,
}: AnimatedCanvasProps) {
  const canvasRef = useRef<HTMLCanvasElement>()

  const { width, height } = useMeasureObserver(canvasRef)
  const {
    left: firstSequenceAnimatedRefLeft,
    width: firstSequenceAnimatedRefWidth,
    height: firstSequenceAnimatedRefHeight,
  } = useMeasureObserver(firstSequenceAnimatedRef, 'getBoundingClientRect')

  const {
    left: secondSequenceAnimatedRefLeft,
    width: secondSequenceAnimatedRefWidth,
    height: secondSequenceAnimatedRefHeight,
  } = useMeasureObserver(secondSequenceAnimatedRef, 'getBoundingClientRect')
  const {
    left: thirdSequenceAnimatedRefLeft,
    width: thirdSequenceAnimatedRefWidth,
    height: thirdSequenceAnimatedRefHeight,
  } = useMeasureObserver(thirdSequenceAnimatedRef, 'getBoundingClientRect')

  const firstImageCover = cover(
    { width: firstSequenceImage?.width, height: firstSequenceImage?.height },
    {
      width: firstSequenceAnimatedRefWidth,
      height: firstSequenceAnimatedRefHeight,
    },
  )
  const thirdImageCoverFrom = cover(
    { width: thirdSequenceImage?.width, height: thirdSequenceImage?.height },
    {
      width: secondSequenceAnimatedRefWidth,
      height: secondSequenceAnimatedRefHeight,
    },
  )
  const thirdImageCoverTo = cover(
    { width: thirdSequenceImage?.width, height: thirdSequenceImage?.height },
    {
      width: thirdSequenceAnimatedRefWidth,
      height: thirdSequenceAnimatedRefHeight,
    },
  )

  const animationSteps = {
    image: {
      x: [
        firstSequenceAnimatedRefLeft + firstImageCover.left,
        firstSequenceAnimatedRefLeft + firstImageCover.left,
        firstImageCover.left,
        thirdImageCoverFrom.left,
        thirdImageCoverFrom.left,
      ],
      y: [
        height / 2 - firstSequenceAnimatedRefHeight / 2 + firstImageCover.top,
        height / 2 - firstSequenceAnimatedRefHeight / 2 + firstImageCover.top,
        thirdImageCoverFrom.top,
        thirdImageCoverFrom.top,
        thirdImageCoverFrom.top,
      ],
      width: [
        firstImageCover.width,
        firstImageCover.width,
        firstImageCover.width,
        firstImageCover.width,
        firstImageCover.width,
      ],
      height: [
        firstImageCover.height,
        firstImageCover.height,
        firstImageCover.height,
        firstImageCover.height,
        firstImageCover.height,
      ],
      opacity: [1, 1, 0, 0, 0],
      scale: [1.5, 1, 1, 1, 1],
    },
    thirdSequenceImage: {
      x: [
        thirdSequenceAnimatedRefLeft + thirdImageCoverFrom.left,
        thirdSequenceAnimatedRefLeft + thirdImageCoverFrom.left,
        thirdSequenceAnimatedRefLeft + thirdImageCoverFrom.left,
        thirdSequenceAnimatedRefLeft + thirdImageCoverTo.left,
        thirdSequenceAnimatedRefLeft + thirdImageCoverTo.left,
      ],
      y: [
        thirdImageCoverFrom.top,
        thirdImageCoverFrom.top,
        thirdImageCoverFrom.top,
        thirdImageCoverTo.top,
        thirdImageCoverTo.top,
      ],
      width: [
        thirdImageCoverFrom.width,
        thirdImageCoverFrom.width,
        thirdImageCoverFrom.width,
        thirdImageCoverTo.width,
        thirdImageCoverTo.width,
      ],
      height: [
        thirdImageCoverFrom.height,
        thirdImageCoverFrom.height,
        thirdImageCoverFrom.height,
        thirdImageCoverTo.height,
        thirdImageCoverTo.height,
      ],
      opacity: [0, 0, 0, 1, 1],
      scale: [1, 1, 1, 1, 1],
    },
    smallCircle: {
      x: [
        width,
        firstSequenceAnimatedRefLeft + firstSequenceAnimatedRefWidth / 2,
        secondSequenceAnimatedRefLeft,
        width / 2,
        0,
      ],
      y: [
        height / 2,
        height / 2,
        height / 2 - secondSequenceAnimatedRefHeight / 2,
        0.2 * height,
        0,
      ],
      size: [0, 0, secondSequenceAnimatedRefHeight / 2, 0, 0],
    },
    mask: {
      x: [
        width + width * 0.1,
        firstSequenceAnimatedRefLeft,
        secondSequenceAnimatedRefLeft,
        thirdSequenceAnimatedRefLeft,
        thirdSequenceAnimatedRefLeft,
      ],
      y: [
        height / 2,
        height / 2 - firstSequenceAnimatedRefHeight / 2,
        height / 2 - secondSequenceAnimatedRefHeight / 2,
        -width / 2,
        -width / 2,
      ],
      size: [
        0,
        firstSequenceAnimatedRefWidth,
        secondSequenceAnimatedRefWidth,
        thirdSequenceAnimatedRefWidth,
        thirdSequenceAnimatedRefWidth,
      ],
      radius: [
        0,
        firstSequenceAnimatedRefWidth / 2,
        0,
        thirdSequenceAnimatedRefWidth / 2,
        thirdSequenceAnimatedRefWidth / 2,
      ],
    },
  }
  // Record scroll progress and spring it
  const animatedCanvasRef = useRef(null)
  useElevator({
    ref: animatedCanvasRef,
    friction: 0.08,
    endOffset: -height,
    onProgress: (progress) => {
      scrollYProgress.set(progress)
    },
  })
  const scrollYProgress = useMotionValue(0)

  const maskXTransform = useScrollTransform(
    animationSteps.mask.x,
    scrollYProgress,
  )
  const maskYTransform = useScrollTransform(
    animationSteps.mask.y,
    scrollYProgress,
  )
  const maskSizeTransform = useScrollTransform(
    animationSteps.mask.size,
    scrollYProgress,
  )
  const maskRadiusTransform = useScrollTransform(
    animationSteps.mask.radius,
    scrollYProgress,
  )

  const imageXTransform = useScrollTransform(
    animationSteps.image.x,
    scrollYProgress,
  )
  const imageYTransform = useScrollTransform(
    animationSteps.image.y,
    scrollYProgress,
  )
  const imageWidthTransform = useScrollTransform(
    animationSteps.image.width,
    scrollYProgress,
  )
  const imageHeightTransform = useScrollTransform(
    animationSteps.image.height,
    scrollYProgress,
  )
  const imageOpacityTransform = useScrollTransform(
    animationSteps.image.opacity,
    scrollYProgress,
  )
  const imageScaleTransform = useScrollTransform(
    animationSteps.image.scale,
    scrollYProgress,
  )

  const thirdSequenceImageXTransform = useScrollTransform(
    animationSteps.thirdSequenceImage.x,
    scrollYProgress,
  )
  const thirdSequenceImageYTransform = useScrollTransform(
    animationSteps.thirdSequenceImage.y,
    scrollYProgress,
  )
  const thirdSequenceImageWidthTransform = useScrollTransform(
    animationSteps.thirdSequenceImage.width,
    scrollYProgress,
  )
  const thirdSequenceImageHeightTransform = useScrollTransform(
    animationSteps.thirdSequenceImage.height,
    scrollYProgress,
  )
  const thirdSequenceImageOpacityTransform = useScrollTransform(
    animationSteps.thirdSequenceImage.opacity,
    scrollYProgress,
  )
  const thirdSequenceImageScaleTransform = useScrollTransform(
    animationSteps.thirdSequenceImage.scale,
    scrollYProgress,
    easeInOutCubic,
  )

  const smallCircleXTransform = useScrollTransform(
    animationSteps.smallCircle.x,
    scrollYProgress,
  )
  const smallCircleYTransform = useScrollTransform(
    animationSteps.smallCircle.y,
    scrollYProgress,
  )
  const smallCircleSizeTransform = useScrollTransform(
    animationSteps.smallCircle.size,
    scrollYProgress,
  )

  // Scale canvas fot HDPI screens
  useEffect(() => {
    const dpr = window.devicePixelRatio
    canvasRef.current.width = width * dpr
    canvasRef.current.height = height * dpr
    var ctx = canvasRef.current.getContext('2d', {
      alpha: true,
      desynchronized: true,
    })
    ctx.scale(dpr, dpr)
  }, [width, height])

  const [firstSequenceImageRef, firstSequenceImageIsLoaded] = useCanvasImage(
    firstSequenceImage?.src,
  )
  const [thirdSequenceImageRef, thirdSequenceImageIsLoaded] = useCanvasImage(
    thirdSequenceImage?.src,
  )

  const draw = useCallback(() => {
    const ctx = canvasRef.current.getContext('2d', {
      alpha: true,
      desynchronized: true,
    })
    ctx.globalCompositeOperation = 'source-over'
    ctx.globalAlpha = 1
    ctx.clearRect(0, 0, width, height)

    drawRoundSquare(
      ctx,
      maskXTransform.get(),
      maskYTransform.get(),
      maskSizeTransform.get(),
      maskRadiusTransform.get(),
    )
    ctx.fill()

    ctx.globalCompositeOperation = 'source-atop'

    if (imageOpacityTransform.get()) {
      ctx.globalAlpha = imageOpacityTransform.get()
      drawImage(
        ctx,
        firstSequenceImageRef.current,
        imageXTransform.get() * imageScaleTransform.get(),
        imageYTransform.get() * imageScaleTransform.get(),
        imageWidthTransform.get() * imageScaleTransform.get(),
        imageHeightTransform.get() * imageScaleTransform.get(),
      )
    }
    if (thirdSequenceImageOpacityTransform.get()) {
      ctx.globalAlpha = thirdSequenceImageOpacityTransform.get()
      drawImage(
        ctx,
        thirdSequenceImageRef.current,
        thirdSequenceImageXTransform.get() *
          thirdSequenceImageScaleTransform.get(),
        thirdSequenceImageYTransform.get() *
          thirdSequenceImageScaleTransform.get(),
        thirdSequenceImageWidthTransform.get() *
          thirdSequenceImageScaleTransform.get(),
        thirdSequenceImageHeightTransform.get() *
          thirdSequenceImageScaleTransform.get(),
      )
    }
    ctx.globalAlpha = 1

    if (smallCircleSizeTransform.get()) {
      ctx.globalCompositeOperation = 'destination-out'
      drawCircle(
        ctx,
        smallCircleXTransform.get(),
        smallCircleYTransform.get(),
        smallCircleSizeTransform.get(),
      )

      ctx.fill()
    }
  }, [width, height, firstSequenceImageIsLoaded, thirdSequenceImageIsLoaded])

  // Draw on canvas
  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d', {
      alpha: true,
      desynchronized: true,
    })
    const unsub = scrollYProgress.onChange(draw)
    draw()
    return () => {
      ctx.clearRect(0, 0, width, height)
      unsub()
    }
  }, [draw, width, height])

  return (
    <div ref={animatedCanvasRef} className={cx(css.AnimatedCanvas, className)}>
      <div className={css.canvasContainer}>
        <canvas ref={canvasRef} className={cx(css.canvas)} />
      </div>
    </div>
  )
}

AnimatedCanvas.defaultProps = {}

export default AnimatedCanvas
