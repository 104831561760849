interface Dimension {
  width: number
  height: number
}

export function cover(target: Dimension, container: Dimension) {
  return calculate(target, container, true)
}

export function contain(target: Dimension, container: Dimension) {
  return calculate(target, container, false)
}

function calculate(target: Dimension, container: Dimension, cover: boolean) {
  var containerW = container.width
  var containerH = container.height
  var targetW = target.width
  var targetH = target.height

  var rw = containerW / targetW
  var rh = containerH / targetH
  var r

  if (cover) {
    r = rw > rh ? rw : rh
  } else {
    r = rw < rh ? rw : rh
  }

  return {
    left: (containerW - targetW * r) >> 1,
    top: (containerH - targetH * r) >> 1,
    width: targetW * r,
    height: targetH * r,
    scale: r,
  }
}
