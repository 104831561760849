import classnames from 'classnames/bind'
import { stringify } from 'querystring'
import React from 'react'

import Link, { LinkProps } from '~/components/Link'
import RichText, { RichTextProps } from '~/components/RichText'
import { ReactComponent as PlusIcon } from '~/components/icons/plus.svg'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface LinkListEntryProps {
  link?: LinkProps
  label?: RichTextProps['render']
}

export interface LinkListProps {
  className?: string
  entries?: LinkListEntryProps[]
}

function LinkList({ className, entries }: LinkListProps) {
  const linkTextStyle = useStyle({
    textPreset: 'rich-text-variant-1',
    color: 'dark-gray',
  })
  const gridStyle = useStyle({ grid: 'base-grid' })
  return (
    <div className={cx(css.LinkList, className, gridStyle)}>
      {entries?.length > 0 && (
        <ul className={css.links}>
          {entries?.map((entry, index) => {
            console.log(entry)
            return (
              <li
                key={`${entry?.link?.href}-${index}`}
                className={cx(css.linkWrapper, linkTextStyle)}>
                <Link className={css.link} {...entry?.link}>
                  <RichText render={entry?.label} />
                  <PlusIcon className={css.icon} />
                </Link>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

LinkList.defaultProps = {}

export default LinkList
