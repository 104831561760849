import classnames from 'classnames/bind'
import { m, useMotionValue, useTransform } from 'framer-motion'
import React, { useRef } from 'react'

import {
  useIsHover,
  useIsomorphicLayoutEffect,
  useIsTouchScreen,
} from '@unlikelystudio/react-hooks'

import Link, { LinkProps } from '~/components/Link'

import { useCssVariables } from '~/providers/CssVariablesProvider'
import { usePageSettings } from '~/providers/PageSettingsProvider'
import { useStyle } from '~/providers/StyleProvider'

import useElevator from '~/hooks/useElevator'

import { ReactComponent as Background } from './background.svg'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface BigLinkProps {
  className?: string
  link?: LinkProps
  label?: string
}

const circleVariants = {
  initial: {
    x: '-100%',
  },
  hover: {
    x: '0%',
  },
}
const textVariants = {
  initial: {
    x: -60,
  },
  hover: {
    x: 0,
  },
}

const textSmallScreenVariants = {
  initial: {
    x: -35,
  },
  hover: {
    x: 0,
  },
}

const backgroundVariants = {
  initial: {
    z: 0,
  },
  hover: {
    z: 90,
  },
}

const transition = {
  type: 'spring',
  damping: 30,
  stiffness: 175,
}

function BigLink({ className, link, label }: BigLinkProps) {
  const [isHover, events] = useIsHover()
  const isTouchScreen = useIsTouchScreen()
  const gridStyle = useStyle({ grid: 'base-grid' })
  const titleStyle = useStyle({ textPreset: 'title-50-148' })
  const componentRef = useRef(null)
  const { backgroundColor } = usePageSettings()

  const scrollYProgress = useMotionValue(0)

  useElevator({
    ref: componentRef,
    friction: 0.04,
    onProgress: (p) => {
      scrollYProgress.set(p)
    },
  })

  const rotate = useTransform(scrollYProgress, [0, 1], [40, -40])

  const baseAnimation = {
    initial: isTouchScreen ? 'hover' : 'initial',
    animate: isTouchScreen ? 'hover' : isHover ? 'hover' : 'initial',
    transition: transition,
  }

  return (
    <div
      ref={componentRef}
      className={cx(css.BigLink, className, gridStyle, titleStyle)}>
      <span
        className={css.border}
        style={{ borderColor: backgroundColor }}></span>
      <Link
        className={css.linkContainer}
        {...link}
        {...(isTouchScreen ? {} : events)}>
        <m.div
          {...baseAnimation}
          variants={backgroundVariants}
          className={css.background}>
          <m.div
            className={css.background}
            style={{
              rotate,
            }}>
            <Background className={css.backgroundIcon} />
          </m.div>
        </m.div>
        <div {...link} className={css.link}>
          <span className={css.circle}>
            <m.span
              {...baseAnimation}
              variants={circleVariants}
              className={css.innerCircle}
            />
          </span>
          <m.span
            {...baseAnimation}
            variants={textVariants}
            className={cx(css.largeScreenLabel, css.label)}>
            {label}
          </m.span>
          <m.span
            {...baseAnimation}
            variants={textSmallScreenVariants}
            className={cx(css.smallScreenLabel, css.label)}>
            {label}
          </m.span>
        </div>
      </Link>
    </div>
  )
}

BigLink.defaultProps = {}

export default BigLink
