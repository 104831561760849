import classnames from 'classnames/bind'
import React, { DOMAttributes, ReactChild, forwardRef, Ref } from 'react'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface RatioProps extends DOMAttributes<any> {
  className?: string
  preset?: string
  ratio?: number
  children: (className: string) => ReactChild
}
/**
 * Ratio component to keep a proportion
 * @param props
 * @returns Component
 */
const Ratio = forwardRef(
  (
    { ratio, className, preset, children, ...rest }: RatioProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const presetStyle = useStyle({
      ratio: preset,
    })
    const containerStyle = cx(css.ratioContainer, presetStyle)

    return (
      <div ref={ref} {...rest} className={cx(css.Ratio, className)}>
        <div
          className={containerStyle}
          style={ratio ? { paddingBottom: `${(1 / ratio) * 100}%` } : {}}>
          {children(css.children)}
        </div>
      </div>
    )
  },
)

Ratio.defaultProps = {}

export default Ratio
