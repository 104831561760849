import classnames from 'classnames/bind'
import React, { Ref } from 'react'

import MediaWithPlaceholder, {
  MediaWithPlaceholderProps,
} from '~/components/MediaWithPlaceholder'
import Ratio from '~/components/Ratio'

import { useStyle } from '~/providers/StyleProvider'

import Sequence from '../Sequence'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface ThirdSequenceProps {
  className?: string
  animatedRef?: Ref<any>
  media?: MediaWithPlaceholderProps
}

function ThirdSequence({ className, media, animatedRef }: ThirdSequenceProps) {
  const gridStyle = useStyle({ grid: 'base-grid' })
  return (
    <Sequence className={cx(className, css.ThirdSequence, css.stickyContainer)}>
      <div className={cx(css.thirdSequenceContainer, gridStyle)}>
        {media?.imageProps && (
          <Ratio ref={animatedRef} className={cx(css.ratio)} ratio={1}>
            {(className) => (
              <MediaWithPlaceholder
                className={cx(className, css.image)}
                layout="fill"
                objectFit="cover"
                {...media}
              />
            )}
          </Ratio>
        )}
      </div>
    </Sequence>
  )
}

ThirdSequence.defaultProps = {}

export default ThirdSequence
