import classnames from 'classnames/bind'
import React from 'react'

import { useStyle } from '~/providers/StyleProvider'

import RichText, { RichTextProps } from '../RichText'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface RichTextListProps {
  richTexts: RichTextProps['render'][]
  className?: string
}

function RichTextList({ className, richTexts }: RichTextListProps) {
  const gridStyle = useStyle({ grid: 'base-grid' })
  const richTextStyle = useStyle({ textPreset: 'rich-text-variant-1' })
  return (
    <ul className={cx(css.RichTextList, gridStyle, className)}>
      {richTexts?.map((richText, index) => {
        return (
          <li className={css.richText} key={index}>
            <RichText className={richTextStyle} render={richText} />
          </li>
        )
      })}
    </ul>
  )
}

RichTextList.defaultProps = {}

export default RichTextList
